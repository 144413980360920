import React, { Component } from "react";
import { Container, Card, Button } from "react-bootstrap";

import CardWithImageComponent from "../components/CardWithImageComponent.js";
import { applyNavigationEffect } from "../effects/NavigationEffect.js";

class Home extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    applyNavigationEffect();
  }

  render() {
    return (
      <div id="home" className="webpage">
        <div id="small-header">
          <h1>Burgverein Hohenfels e.V.</h1>
          <h3>Willkommen auf unserer Website</h3>
        </div>

        <div id="laufzeileQuestions" className="cursor-pointer-large">
          <a href="/85fragen">
            <img
              id="homepageQuestionsLogo"
              src="/assets/images/logo_85_fragen.png"
              alt="85fragen"
            />
          </a>
        </div>

        <Container>
          <CardWithImageComponent
            id="logo"
            className="card-with-image"
            header="Unser Vereinslogo"
            image="/assets/images/general_images/logo-transparent.png"
            text={<a href="/verein/logo">»Zur Beschreibung«</a>}
          />
          <Card>  
            <Card.Header className="small-h1">
              <a href="/freizeit/sonderveranstaltungen">Aktuelle Termine</a>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>
                  31.01.2025 - Jahreshauptversammlung im DGH Allendorf (interne
                  Veranstaltung)
                </li>
                <li>
                  12.07.2025 - Mittsommernacht am Hohenfels (21 Uhr - 23 Uhr)
                </li>
                <li>
                  14.09.2025 - Tag des offenen Denkmals: der
                  hochmittelalterliche Anlagenkomplex Hohenfels - „Erwanderung
                  von einem Hektar voller Geschichte“
                </li>
              </ul>
            </Card.Body>
          </Card>
        </Container>
      </div>
    );
  }
}

export default Home;
