// App.js
import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import axios from "axios";

import { BsInstagram, BsFillEnvelopeFill } from "react-icons/bs";
import NavigationBar from "./components/Navbar";

import Home from "./pages/Home";

import DataProtection from "./pages/DataProtection";
import Imprint from "./pages/Imprint";
import BecomeMember from "./pages/BecomeMember";

// Ruin pages
import History from "./pages/ruin/History";
import Position from "./pages/ruin/Position";
import RuinImages from "./pages/ruin/Images";
import Visualization from "./pages/ruin/Visualization";

// Guild pages
import Tasks from "./pages/guild/Tasks";
import GuildHistory from "./pages/guild/History";
import AssociationChairman from "./pages/guild/AssociationChairman";
import Statute from "./pages/guild/Statute";
import AssociationLogo from "./pages/guild/AssociationLogo";

// Project pages
import CellarProject from "./pages/projects/Cellar";
import ModelProject from "./pages/projects/Models";
import DonationBoard from "./pages/projects/DonationBoard";
import LectureTrail from "./pages/projects/LectureTrail";
import GuestBook from "./pages/GuestBook";

// Lecture Trail pages
import EntranceBuchenau from "./pages/lecturetrail/EntranceBuchenau";
import EntranceCemetery from "./pages/lecturetrail/EntranceCemetery";
import EntranceSportField from "./pages/lecturetrail/EntranceSportField";

import Competition from "./pages/special/Competition850years";
// import CompetitionSol from "./pages/special/CompetitionSol";
import CompetionQuestions from "./pages/special/CompetionQuestions";

const TRACKING_ID = "G-3N1MT9W9EB"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const tagManagerArgs = {
  gtmId: TRACKING_ID,
};

TagManager.initialize(tagManagerArgs);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalVisitors: 0,
      visitorCountUpdated: false, // Track if visitor count was updated
    };
    this.handleAcceptCookies = this.handleAcceptCookies.bind(this);
    this.updateVisitorCount = this.updateVisitorCount.bind(this);
  }

  async componentDidMount() {
    // Check if cookies are accepted
    if (getCookieConsentValue() === "true" && !this.state.visitorCountUpdated) {
      await this.updateVisitorCount();
      this.setState({ visitorCountUpdated: true });
    }

    // Fetch total visitors
    try {
      const response = await axios.post("https://burgverein-hohenfels.de/visitors/total");
      this.setState({ totalVisitors: response.data.totalVisitors });
    } catch (error) {
      console.error("Failed to fetch total visitors:", error);
    }
  }

  async updateVisitorCount() {
    try {
      const response = await axios.post("https://burgverein-hohenfels.de/visitors/update");
      console.log("Visitor count updated:", response.data);
    } catch (error) {
      console.error("Failed to update visitor count:", error);
    }
  }

  handleAcceptCookies() {
    // Save cookie consent
    document.cookie = "cookies=true; path=/; max-age=" + 60 * 60 * 24 * 150;

    // Update visitor count
    this.updateVisitorCount();
    this.setState({ visitorCountUpdated: true });
  }

  render() {
    const cookieAccepted = getCookieConsentValue() === "true";

    return (
      <>
        <header>
          <NavigationBar />
        </header>
        <main>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ruinen/geschichte" element={<History />} />
              <Route path="/ruinen/lage" element={<Position />} />
              <Route path="/ruinen/bilder" element={<RuinImages />} />
              <Route path="/ruinen/rekonstruktions-modelle" element={<Visualization />} />
              <Route path="/verein/aufgaben" element={<Tasks />} />
              <Route path="/verein/historie" element={<GuildHistory />} />
              <Route path="/verein/vorstand" element={<AssociationChairman />} />
              <Route path="/verein/satzung" element={<Statute />} />
              <Route path="/verein/logo" element={<AssociationLogo />} />
              <Route path="/projekte/keller" element={<CellarProject />} />
              <Route path="/projekte/modell" element={<ModelProject />} />
              <Route path="/projekte/lehrpfad" element={<LectureTrail />} />
              <Route path="/projekte/spendentafel" element={<DonationBoard />} />
              <Route path="/mitglied-werden" element={<BecomeMember />} />
              <Route path="/gaestebuch" element={<GuestBook />} />
              <Route path="/85fragen" element={<CompetionQuestions />} />
              <Route path="/datenschutz" element={<DataProtection />} />
              <Route path="/impressum" element={<Imprint />} />
              <Route path="/lehrpfad/eingang/buchenau" element={<EntranceBuchenau />} />
              <Route path="/lehrpfad/eingang/friedhof" element={<EntranceCemetery />} />
              <Route path="/lehrpfad/eingang/sportplatz" element={<EntranceSportField />} />
              {/*
              <Route path="/wettbewerb" element={<Competition />} />
              */}
              
              <Route path="*" element={<Home />} />
            </Routes>
          </Router>
        </main>
        {!cookieAccepted && (
          <CookieConsent
            location="bottom"
            buttonText="Ich stimme zu"
            cookieName="cookies"
            buttonClasses="btn btn-dark"
            expires={150}
            onAccept={this.handleAcceptCookies}
          >
            Diese Website verwendet Cookies, um sicherzustellen, dass du die beste
            Erfahrung auf unserer Website erhältst.
          </CookieConsent>
        )}
        <footer>
          <div id="visitors">
            Gesamtbesucherzahl: {this.state.totalVisitors}
          </div>
          <div id="insta-link" className="footer-link">
            <BsInstagram />
            <a href="https://www.instagram.com/burgverein_hohenfels_lahn/" target="_blank" rel="noreferrer">
              Folgt uns auf Instagram
            </a>
          </div>
          <div id="mail-link" className="footer-link">
            <BsFillEnvelopeFill />
            <a href="mailto:burg.hohenfels@googlemail.com" id="footer-mail">
              Schreibt uns eine E-Mail
            </a>
          </div>
          <div id="copyright">Copyright 2024, Burgverein Hohenfels e.V.</div>
        </footer>
      </>
    );
  }
}

export default App;
