import React, { Component } from "react";
import { Accordion, Container, Card, Modal } from "react-bootstrap";
import { applyNavigationEffect } from "../../effects/NavigationEffect.js";
import CardWithImageComponent from "../../components/CardWithImageComponent.js";

class CompetionQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedImage: null,
    };
  }

  componentDidMount() {
    applyNavigationEffect(); // Call the navigation effect
  }

  // Function to handle image click
  handleImageClick = (imageSrc) => {
    this.setState({
      showModal: true,
      selectedImage: imageSrc,
    });
  };

  // Function to close the modal
  handleCloseModal = () => {
    this.setState({
      showModal: false,
      selectedImage: null,
    });
  };

  render() {
    return (
      <div id="questionsPage">
        <Container className="main-container">
          <CardWithImageComponent
            image="/assets/images/logo_85_fragen.png"
            id="logo_img_questions"
            className="card-with-image"
            header="85 Fragen – 85 Antworten: Entdecke die Geheimnisse des Hohenfels!"
            content={
              <>
                Zum 850-jährigen Jubiläum des Hohenfels wollten wir wissen:{" "}
                Welche Fragen rund um den Hohenfels interessieren euch am
                meisten? Mit unserer Aktion „85 Fragen an den Hohenfels“ haben
                wir dazu aufgerufen, uns Eure Fragen zu Burgen, Rittern, Sagen
                und Geheimnissen rund um den Hohenfels zu stellen. Die Aktion
                lief über Instagram, unsere Website und das Dautphetaler
                Blättchen. 19 spannende Fragen haben uns erreicht, die wir in
                den nächsten Tagen, nach und nach beantworten! Hier findest du
                unsere Antworten auf Fragen wie:
                <ul>
                  <li>Wie hoch waren die Burgtürme?</li>
                  <li>
                    Welche Burgen lagen in der Nachbarschaft und wie war das
                    Verhältnis zu deren Besitzern?
                  </li>
                  <li>Wie alt ist die Burg?</li>
                  <li>Wo ist der Schatz des Hohenfels vergraben?</li>
                </ul>
                Unsere Antworten basieren auf historischen Urkunden,
                archäologischen Erkenntnissen und Vergleichen mit anderen Burgen
                der Region. Doch Geschichte ist lebendig – manchmal bleiben
                Hypothesen und offene Fragen, die vielleicht keine klare Antwort
                geben, aber die Spannung, Faszination und das Mysterium des
                Hohenfels noch erhöhen. &nbsp;
                <div>
                  <br />
                  <b>Du hast unsere „85 Fragen“-Aktion verpasst?</b> <br />
                  Du hast eine spannende Frage, die dir unter den Nägeln brennt?{" "}
                  <br />
                  Oder möchtest du einfach mehr Details erfahren? Wir freuen uns
                  auf deine Fragen!
                  <br />
                  <a href="mailto:burg.hohenfels@googlemail.com">
                    <b>Schreib uns eine Mail!</b>
                  </a>
                  <br />
                  Oder sprich uns persönlich an – bei einer unserer Führungen
                  oder Veranstaltungen, z. B. am Tag des offenen Denkmals am
                  14.09.2025 direkt am Hohenfels ab ca. 14:30 Uhr.
                </div>
                <br />
                <span>
                  Hinweis: Die Fragen werden im Laufe der nächsten Tage
                  beantwortet
                </span>
              </>
            }
          />
          <CardWithImageComponent
            image="/assets/images/general_images/Gewinner-Bild.jpg"
            className="card-with-image"
            id="gewinner-bild"
            header="85 Fragen Aktion – 3 Gewinnerinnen – 1 Fokus"
            content={
              <>
                Die „850 Jahre Hohenfels - 85 Fragen“-Aktion hat spannende
                Fragen hervorgebracht.
                <br /> Ziel war es, die Geschichte des Hohenfels lebendig zu
                halten. Vier der durchweg faszinierenden Fragen konnten wir
                bereits beantworten – und weitere werden folgen! Ein ganz
                herzliches Dankeschön an alle Teilnehmer!
                <br />
                Die 3 Gewinnerinnen im Alter von 12 bis 80 Jahren erhielten
                einen personalisierten Gedichtband als Dank für ihre Neugier und
                ihr Interesse – 132 Seiten zum Stöbern, Schmunzeln und
                Unterhalten.
                <br />
                Gewonnen haben (von links nach rechts):
                <ul>
                  <li>Sophia R.</li>
                  <li>Stefanie R.</li>
                  <li>Renate A.</li>
                </ul>
                Alle Gewinnerinnen waren mit der Veröffentlichung des Fotos
                einverstanden
              </>
            }
          />
          <Accordion id="questionAccordion">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Wie viel Wasser war ungefähr im Burggraben?
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordion-image-wrapper">
                  <img
                    src="/assets/images/fragen/grundriss-burggraben.jpg"
                    alt="grundriss-burggraben"
                    width={200}
                    onClick={() =>
                      this.handleImageClick(
                        "/assets/images/fragen/grundriss-burggraben.jpg"
                      )
                    }
                  />
                </div>
                Die Antwort ist einfach und fazinierend zugleich: Die Burggräben
                der beiden Hohenfels-Burgen dienten als bedeutende
                Verteidigungsmaßnahme, obwohl sie KEIN Wasser führten.
                <br />
                Ein Burggraben verhinderte die direkte Annäherung feindlicher
                Truppen, erschwerte die Unterminierung der Mauern und zwang
                Angreifer, sich auf wenige, leicht zu verteidigende Zugänge zu
                konzentrieren, vermutlich Zugbrücken. Durch steile Böschungen
                und zusätzliche Hindernisse (z.B. spitze Pfähle und
                Dornengestrüpp) sowie die natürliche Höhenlage der Burgen,
                verstärkte der Burggraben den Schutz erheblich. Diese Art von
                Trockengraben war bei Höhenburgen eine bewährte
                Verteidigungsstrategie. Sowohl bei der Hohenfels-Ostburg wie
                auch bei der Westburg ist ein Burggraben noch deutlich als
                Geländeformation erkennbar.
                <br />
                Das Bild zeigt die Aufteilung der Hohenfels-Westburg, rot
                markiert die Fläche des Vorplatzes (3) und des Burggrabens.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Wie hoch waren die Burgtürme des Hohenfels?
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordion-image-wrapper">
                  <img
                    src="/assets/images/fragen/grundriss-tuerme.jpg"
                    alt="grundriss-türme"
                    width={200}
                    onClick={() =>
                      this.handleImageClick(
                        "/assets/images/fragen/grundriss-tuerme.jpg"
                      )
                    }
                  />
                </div>
                Dank dieser Frage haben wir uns intensiv mit dem Aussehen der
                Hohenfels-Westburg beschäftigt. <br />
                Anhand moderner Bildtechnik kann sowohl die Lage der einzelnen
                Gebäude, wie auch die Mauerstärke der Türme gut rekonstruiert
                werden. Zusammen mit Vergleichen von anderen Burgen dieser Zeit,
                lassen sich fundierte Rückschlüsse ziehen.
                <br />
                <br />
                <b>Der Bergfried – das Herzstück der Westburg</b> <br />
                In der Westburg des Hohenfels spielte der Bergfried (Turm 12 in
                der Skizze) eine zentrale Rolle. Als höchster und massivster
                Turm diente er nicht nur der militärischen Verteidigung, sondern
                war auch ein Symbol für die Macht der Hohenfelser Herren. <br />
                <br />
                <b>Höhenrekonstruktion: </b>
                <br />
                Basierend auf der Mauerstärke und dem Fundament lässt sich
                ableiten, dass der Bergfried noch 10 bis 13 Meter über das
                heutige Bodenniveau hinausragte. In Verbindung mit der erhöhten
                Lage der Westburg auf einem Felsen, wurde der Besucher der die
                Sicherheitsbereiche zur Burg durchschritt, mit jedem Meter dem
                er dem Bergfried näher kam mental kleiner. Spätestens hinter dem
                Torhaus war der Besucher dem Bergfried auch körperlich
                ausgeliefert. Neben der Symbolwirkung war er wichtiger
                Aussichtspunkt in große Teile der von hier verwalteten Cent
                Dautphe und zentraler Verteidigungsposten für Angreifer, welche
                die vorherigen Sicherheitsbereiche überwunden hatten. <br />
                <br />
                <b>Weitere Türme in der Hohenfels-Westburg:</b>
                <br />
                Neben dem Bergfried gab es noch mindestens einen weiteren, wohl
                etwas kleineren Wehrturm <br />
                (Turm 13 in der Skizze). <br />
                Dieser hatte eine weniger symbolische und umso größere
                strategische Funktion:
                <ul>
                  <li>
                    Er deckte den Zugang (2), den Vorplatz (3) und den Vorhof
                    (4) der Hauptburg ab und sicherte so den verengten Zugang in
                    den Innenhof (5) der von hier aus ebenfalls gut überwacht
                    werden konnte. Er bot eine erhöhte Schussposition für Bogen-
                    und Armbrustschützen.
                  </li>

                  <li>
                    Seine genaue Höhe dürfte etwas niedriger als der Bergfried
                    gewesen sein, wir gehen von 6-8 Meter aus.
                  </li>
                </ul>
                Durch die exponierte Lage auf einem Bergkamm wirkte die gesamte
                Westburg mit dem daraus herausragenden Bergfried noch
                gewaltiger. Von allen Seiten war sie ein weithin sichtbares
                Zeichen der Macht der Herren von Hohenfels. <br />
                <br />
                <b>Warum sind heute keine Reste mehr sichtbar?</b>
                <br />
                Nach der Aufgabe der Burg (um 1300) verfielen Türme, Burgen und
                Wehranlagen über die Jahrhunderte. <br />
                Die Fundamente verschwanden im Laufe der Jahrhunderte unter Erde
                und Vegetation. Aktuell arbeiten wir an einer
                Modell-Visualisierung des Hohenfels, um zu veranschaulichen, wo
                genau auf dem 1-2 Hektar großen Anlagenkomplex im
                Hochmittelalter etwas zu sehen war und wie die Türme und Gebäude
                einst ausgesehen haben könnten.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                Wo ist der Schatz vom Hohenfels?
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordion-image-wrapper">
                  <img
                    src="/assets/images/fragen/schatz.jpg"
                    alt="grundriss-burggraben"
                    width={200}
                    onClick={() =>
                      this.handleImageClick("/assets/images/fragen/schatz.jpg")
                    }
                  />
                </div>
                Bevor wir wissen, WO wir graben müssen, müssen wir wissen,
                WONACH! Das Areal des Hohenfels verteilt sich auf mehrere
                Hektar, heute nur noch schwer zu erkennen, da die Ruinen fast
                völlig überwuchert sind. <br />
                Nur wenige Mauerreste deuten auf eine einst mächtige Burganlage
                hin.
                <br />
                <br />
                <b>1) Silber und Gold oder doch ein anderer Schatz?</b>
                <br />
                Sophie von Brabant, die Stammmutter der hessischen Landgrafen,
                war 1249 der Meinung, dass die Hohenfelser durchaus in der Lage
                waren, 500 Mark Silber zu zahlen. Ein Vermögen, das nach
                heutigen Maßstäben mehrere Millionen Euro entspräche. Doch wo
                ist das Geld hin? Gute Frage, schwierige Antwort – immerhin
                liegen 775 Jahre dazwischen.
                <br />
                <br />
                <b>2) Schatz ja – vergraben ja - aber kein Edelmetall? </b>
                <br />
                Verborgen unter Bäumen, Laub und Humus schlummern die originalen
                Strukturen eines hochmittelalterlichen, außergewöhnlichen
                Wehrkomplexes, der gleich zwei Burgen enthält. Das ist ein
                eingefrorenes Bild des Hochmittelalters, völlig unverändert seit
                dem 13. Jahrhundert! <br />
                Zum Vergleich: Die Schlösser Marburg und Biedenkopf können mit
                einem solch unverfälschten Bild nicht aufwarten, denn dort haben
                spätere Jahrhunderte viele Spuren verwischt. <br />
                Vielleicht ist der Hohenfels selbst der Schatz?
                <br />
                <br />
                <b>
                  3) Den Schatz in Blickweite vom Hohenfels immer vor Augen?{" "}
                </b>
                <br />
                Der Hohenfels war die Schutzmacht der uralten Cent Dautphe (791
                als Mark Dautphe erwähnt), die zu ihren Glanzzeiten rund 130 km²
                Land ausmachte – vom Hühnstein bis zur Sackpfeife. Von der
                Hohenfels-Westburg aus, hatte man das Herzstück des Gebietes,
                die Dautpher Martinskirche immer im Blick! Bis ins Jahr 1249
                hielt die Cent Dautphe durch ihre Gerichtsherren den
                Gegenspielern ungewöhnlich lange und selbstbewusst Stand. Für
                die Landgrafen waren die Hohenfelser fast soetwas wie die
                Gallier für die Römer!
                <br />
                <br />
                <b>4) Ist der Schatz ein historisches Geheimnis?</b>
                <br />
                Das Wappen der Hohenfelser könnte der Schlüssel zu einem
                geschichtlichen Rätsel sein: Anhand einiger Indizien darf
                spekuliert werden, ob die Cent Dautphe den Status einer
                Klein-Grafschaft hatte – allerdings ohne offiziellen Titel. Das
                passt auch zum selbstbewussten Auftreten der Herren von
                Hohenfels. Vielleicht ist das Wappen der Hohenfelser (ein
                Adlerflügel) ein Hinweis auf eine fast autonomen Machtstellung,
                zu der heute keine Urkunde als Grund mehr gefunden werden kann.
                <br />
                <br />
                <b>Abschließende Frage:</b>
                <br />
                Was hätten die Hohenfelser wohl als ihren Schatz definiert?
                <ol>
                  <li>Silber und Gold – greifbar und konkret?</li>
                  <li>Der Hohenfels selbst – als archäologischer Schatz?</li>
                  <li>
                    Die Cent Dautphe – als historische Macht und Einflussgebiet?
                  </li>
                  <li>
                    Das Wappen – als Schlüssel zu einem ungelösten historischen
                    Rätsel?
                  </li>
                </ol>
                Für Sophie von Brabant war jedenfalls klar, was der Schatz der
                Hohenfelser war: die Cent Dautphe und die Gerichtsbarkeit
                darüber. <br />
                Und dieser Schatz wurde – man muss es so sagen: gestohlen!
                <br />
                Schlussbemerkung: <br />
                Das wird nicht die letzte Enteignung in der Geschichte gewesen
                sein… Wer entscheidet eigentlich, wem Macht und Besitz gehören?
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                Wie lange existierte der Hohenfels? <br />
                Wie lange war der Hohenfels bewohnt?
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordion-image-wrapper">
                  <img
                    src="/assets/images/fragen/burg-alter.jpg"
                    alt="grundriss-burggraben"
                    width={200}
                    onClick={() =>
                      this.handleImageClick(
                        "/assets/images/fragen/burg-alter.jpg"
                      )
                    }
                  />
                </div>
                <b>Einleitung – Das Geheimnis der Zeit</b> <br />
                Der Hohenfels – ein Anlagenkomplex mit Wehrtürmen, Gräben,
                Palisaden, einer Burg und sogar einem Schloss (1264 und 1277
                schriftlich überliefert). <br />
                Verborgen in den Nebeln der Vergangenheit und umschlungen von
                den Wurzeln uralter Bäume ruht hier eine Geschichte, die
                Jahrhunderte überdauerte. <br />
                Spürst du diese besondere Aura, wenn du den Hohenfels besuchst?
                Doch wie lange war diese mystische Anlage tatsächlich bewohnt?
                <br />
                <br />
                <b>1. Die Blütezeit des Hohenfels (1174–1249)</b> <br />
                Zwischen 1174 und 1249 erlebte der Hohenfels seine Blütezeit.
                Als Gerichtsherrensitz prägte die Familie von Hohenfels von hier
                aus die Region des heutigen Dautphetal und Biedenkopf und
                hinterließ geheimnisvolle Spuren, die bis heute nachwirken. Doch
                1238 mit der Abtrennung von Biedenkopf und endgültig 1249 mit
                dem Fall unter die Lehnshoheit der hessischen Landgrafen begann
                der langsame Niedergang. <br />
                <br />
                <b>2. Der Niedergang und das Vergessen (1249–1300)</b>
                <br />
                Im Jahr 1249 wurden die Herren von Hohenfels zur Kapitulation
                gezwungen. Doch trotz des Machtverlustes blieb der Hohenfels
                noch einige Jahrzehnte ein Ort des Lebens. 1277 gibt es das
                letzte urkundliche Lebenszeichen des Hohenfels. Archäologische
                Grabungen legen nahe, dass auch 20 Jahre später noch Leben auf
                der Burg herrschte. Doch ob der Hohenfels 1293 tatsächlich
                zerstört wurde oder wirtschaftliche Gründe zum langsamen Verfall
                führten, bleibt im Nebel der Geschichte verborgen. Um 1300
                jedoch war der einstige Ruhm des Anlagenkomplexes Geschichte.
                Meter um Meter holte sich die Natur den Berg zurück und
                verbindet bis heute Natur und Geschichte. Ein Dornröschenschlaf,
                der bis heute anhält.
                <br />
                <br />
                <b>
                  3. Ein Denkmal der Erinnerung und der Geschichte der Zukunft
                </b>
                <br />
                Der Hohenfels steht als Symbol für Vergänglichkeit und – unserer
                Meinung nach – auch für Neubeginn. Seine Geschichte endet nicht
                mit seinem Niedergang. Vielmehr lädt er uns ein, Geschichte zu
                leben und daraus Inspiration für die Zukunft zu schöpfen. „Das
                ZUKÜNFTIGE gestalten, GESCHICHTE bewahren, ERLEBEN ermöglichen!
                – oder kurz: ZUKÜNFTIGE GESCHICHTE ERLEBEN“ <br />
                <br />
                <b>
                  4. Unser heutiges Schaffen ist die Geschichte von morgen!{" "}
                </b>
                <br />
                Jedes Ende ist auch ein Anfang. Der Hohenfels mag nicht mehr
                bewohnt sein, doch er erzählt uns Geschichten von Macht und
                Einfluss, von Recht und Gerechtigkeit, von Gewinnern und
                Verlierern. Der Burgverein Hohenfels e.V. will diesem Ort neues
                Leben einhauchen und ihn für jetzige und zukünftige Generationen
                bewahren und erlebbar machen. <br />
                <br />
                <b>5. Gemeinsam Zukunft gestalten</b>
                <br />
                Bring dich ein! Gestalte mit! <br />
                Fördere gerne dieses Gemeinschaftsprojekt z.B. durch aktive
                Mitgliedschaft (auch passiv möglich), Helfen bei Aktionen oder
                einfaches Mitsprechen und Mitgestalten – jeder Stein trägt zum
                großen Ganzen bei! <br />
                <a href="/mitglied-werden">
                  → Jetzt unterstützen und Zukunft gestalten
                </a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                Wie viele Menschen lebten auf dem Hohenfels?
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordion-image-wrapper">
                  <img
                    src="/assets/images/fragen/bewohner.jpg"
                    alt="bewohner"
                    width={200}
                    onClick={() =>
                      this.handleImageClick(
                        "/assets/images/fragen/bewohner.jpg"
                      )
                    }
                  />
                </div>
                Der Hohenfels – eine mächtige Burganlage aus dem
                Hochmittelalter, umgeben von Legenden und Geheimnissen. <br />
                Doch wie viele Menschen lebten hier wirklich? Und welche
                Aufgaben hatten sie? <br />
                Tauchen Sie ein in die faszinierende Welt des Hohenfels und
                erfahren Sie, wie wir diese Frage mithilfe archäologischer
                Erkenntnisse und historischer Fakten beantworten.
                <br />
                <br />
                <b>Leben auf dem Hohenfels im Hochmittelalter</b>
                <br />
                Der Hohenfels war weit mehr als nur eine Burg – er war ein
                lebendiges Zentrum von Macht, Militär und Verwaltung. Die
                Kombination aus repräsentativer Residenz, strategischer
                Verteidigungsanlage und Verwaltungssitz machte den Hohenfels zu
                einem einzigartigen Machtzentrum in der Region.
                <br />
                <br />
                <b>Schloss Hohenfels (Westburg)</b>
                <br />
                Die Westburg diente als repräsentative Residenz der fast
                grafengleichen Herren von Hohenfels. <br />
                Hier wohnten der Adel und seine engsten Bediensteten, die für
                das Wohl, die Sicherheit und die Pflege der Herrschaftsgebäude
                zuständig waren.
                <ul>
                  <li>
                    Bewohnerzahl: 12-20 Personen (Adel, Bedienstete, Wachposten)
                  </li>
                </ul>
                Die Westburg war politisches und gesellschaftliches Zentrum des
                Hohenfels. Sie war nicht nur Wohnsitz des Adels, sondern auch
                Verwaltungssitz und Ort repräsentativer Empfänge. <br />
                Hier wurden wichtige Entscheidungen getroffen und Gericht
                gehalten, was die Macht und den Einfluss der Herren von
                Hohenfels untermauerte.
                <br />
                <br />
                <b>Burg Hohenfels (Ostburg)</b>
                <br />
                Die Ostburg hatte sehr wahrscheinlich eine militärische
                Bedeutung und diente vor allem als Quartier für die Schutztruppe
                sowie als Lager- und Rüstungsdepot. Besonders in Krisenzeiten
                konnte hier die Schlagkraft der Verteidigung des gesamten
                Anlagenkomplexes enorm gesteigert werden.
                <ul>
                  <li>
                    Bewohnerzahl in Friedenszeiten: 15-30 Personen (Verwaltung,
                    Lagerverwalter, Wehrtruppe)
                  </li>
                  <li>
                    Bewohnerzahl in Kriegszeiten: 30-50 Personen (Verstärkung
                    durch rotierende Wachposten und Schutztruppen)
                  </li>
                </ul>
                Die Ostburg war das Herzstück der militärischen Verteidigung.
                Hier wurden Waffen und Rüstungen gelagert, sodass die
                Schutztruppe im Angriffsfall schnell ausgerüstet und
                einsatzbereit war. Die strategische Lage der Ostburg ermöglichte
                eine rasche Verstärkung der Verteidigung und schützte den
                gesamten Anlagenkomplex vor äußeren Bedrohungen.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                Wo haben die Bewohner des Hohenfels geschlafen?
              </Accordion.Header>
              <Accordion.Body>
                <div className="accordion-image-wrapper">
                  <img
                    src="/assets/images/fragen/schlafen-hohenfels.jpg"
                    alt="schlafen-hohenfels"
                    width={200}
                    onClick={() =>
                      this.handleImageClick(
                        "/assets/images/fragen/schlafen-hohenfels.jpg"
                      )
                    }
                  />
                </div>
                <b>Schlafen auf einer Burg – Zwischen Schutz und Alltag</b>
                <br />
                Wenn wir uns heute in unsere warmen Betten kuscheln, denken wir
                selten darüber nach, wie essenziell eine sichere Schlafstätte
                ist. Doch auf einer mittelalterlichen Burg war der Schlaf keine
                Selbstverständlichkeit – er war oft kalt, unbequem und sogar
                gefährlich. Wo aber fanden die Bewohner des Hohenfels zwischen
                1174 und 1277/1300 ihre Ruhe – und welche Unterschiede gab es
                zwischen Herrschaft, Rittern und einfachen Knechten? <br />
                Der Hohenfels war mehr als eine Wehranlage – die Westburg
                (Schloss Hohenfels) diente als befestigte Residenz mit
                Wohnräumen, Verwaltung und Verteidigungsfunktionen. Zwar war er
                nicht der Hof eines Königs oder Kaisers, doch er war der Sitz
                einer bedeutenden Adelsfamilie, die von hier aus ein 130km²
                Territorium verwaltete und auch darüber hinaus Einfluss und
                Ansehen hatte. Damit war die Burg nicht nur ein Ort der
                Verteidigung, sondern auch des täglichen Lebens – und dazu
                gehörte auch die nächtliche Ruhe.
                <br /><br />
                <b>1174 – Die frühe Wohnkultur auf dem Hohenfels</b><br />
                In der zweiten Hälfte des 12. Jahrhunderts war der Hohenfels
                noch nicht lange einer reinen Wehranlage entwachsen und die
                Wohnverhältnisse waren von der Burgenarchitektur dieser Zeit
                geprägt:
                <ul>
                  <li>
                    Die Herren von Hohenfels und ihre Familie schliefen
                    vermutlich in einer Kemenate – einem beheizbaren Raum mit
                    einer Feuerstelle. Betten bestanden aus Holzgestellen mit
                    Strohsäcken, Fellen oder, für Wohlhabendere, gefüllten
                    Matratzen.
                  </li>
                  <li>
                    Ritter, Knappen und hochrangige Bedienstete dürften in
                    eigenen Kammern oder einfachen Schlafstätten in der Nähe des
                    Herrenhauses geschlafen haben.
                  </li>
                  <li>
                    Die Burgwachen schliefen in Wehrtürmen oder direkt an den
                    Toren – immer einsatzbereit (Eine normale Wachschicht
                    dauerte 4-6 Stunden, aber in Gefahrensituationen oder
                    Kriegszeiten war es auch länger, bis 12 Stunden möglich, mit
                    kurzen Pausen oder Wechseln.)
                  </li>
                  <li>
                    Mägde und Knechte schliefen vermutlich direkt in den
                    Arbeitsräumen.
                  </li>
                </ul>
                Privatsphäre? Ein Luxus, den sich nur die Herrschaft leisten
                konnte. Während der Burgherr in seiner beheizbaren Kemenate mit
                schweren Vorhängen Schutz vor der Zugluft suchte, lagen die
                Bediensteten oft zu mehreren in einem Raum – eng
                aneinandergedrängt, nicht nur aus Platzgründen, sondern auch, um
                sich gegenseitig zu wärmen. Wer Pech hatte, musste im Stall oder
                in einer Ecke der Burgküche schlafen.
                <br /><br />
                <b>1277 – Wohnkomfort auf einer „modernisierten“ Burganlage</b><br />
                Bis zur letzten nachgewiesenen Erwähnung als „Schloss Hohenfels“
                im Jahr 1277 hatte sich vieles verändert. Die Westburg war
                unserer Meinung nach (archäologische Bestätigung ausstehend)
                eine ausgebautere Residenz mit Wohnräumen, einer großen
                Halle/Festsaal und separaten Bereichen für verschiedene
                Personengruppen und Aufgaben, darunter auch zur Verteidigung.
                Der Adel konnte sich mittlerweile mehr Komfort leisten:
                <ul>
                  <li>
                    Die Herrschaftsräume waren repräsentativer geworden.
                    Himmelbetten mit Leinendecken, hölzerne Truhen für Kleidung
                    und bessere Wärmedämmung waren für die Bewohner der Burg
                    möglich.
                  </li>
                  <li>
                    Ritter und Hofstaat hatten eigene Kammern, wenn auch oft mit
                    mehreren Schlafplätzen.
                  </li>
                  <li>
                    Gemeinschaftliche Schlafräume für Bedienstete waren
                    inzwischen häufiger, oft auf einfachen Holzbänken oder
                    Strohlagern.
                  </li>
                  <li>
                    Wachposten schliefen weiterhin an strategischen Punkten der
                    Burg, oft mit ihren Rüstungen in Reichweite.
                  </li>
                </ul>
                Ein wichtiger Unterschied zwischen 1174 und 1277 war auch die
                zunehmende Trennung von Wohn- und Arbeitsbereichen. Während die
                Burg in früheren Zeiten noch stark funktional ausgerichtet war,
                hatte sie sich über die Jahrzehnte zu einer
                hochmittelalterlichen Residenz entwickelt. <br /><br />
                <b>Waren Tiere mit im Schlafraum? </b>
                <br />
                Das Zusammenleben mit Tieren war im Mittelalter nichts
                Ungewöhnliches, doch es gab große Unterschiede zwischen dem
                Leben auf einer Burg und dem der ländlichen Bevölkerung. Während
                Bauern oft mit ihrem Vieh unter einem Dach lebten, um von dessen
                Körperwärme zu profitieren, war das in einer Burg – zumindest
                für die Adligen – anders geregelt.
                <ul>
                  <li>
                    Hunde und Katzen waren in den Wohnräumen durchaus erwünscht.
                    Sie hielten Mäuse und Ratten fern und waren als Jagd- oder
                    Wachtiere wertvolle Begleiter. Ein treuer Hund durfte daher
                    durchaus in der Kammer seines Herrn schlafen.
                  </li>
                  <li>
                    Jagdvögel wie Falken oder Habichte wurden von ihren
                    Besitzern oft in der Nähe gehalten, manchmal sogar im
                    Schlafraum.
                  </li>
                  <li>
                    Nutztiere wie Kühe, Schafe oder Schweine wurden auf Burgen
                    im Gegensatz zu Bauernhäusern meist in separaten Stallungen
                    gehalten. Allerdings gibt es Berichte, dass in besonders
                    kalten Wintern wertvolle Tiere – etwa die besten Pferde – in
                    geschützte Innenhöfe oder überdachte Bereiche nahe der
                    Wohnräume gebracht wurden.
                  </li>
                </ul>
                Auf dem Land hingegen war es üblich, dass Kühe, Schafe oder
                Ziegen im selben Haus untergebracht wurden. Ihre Körperwärme
                diente als natürliche Heizung, und der direkte Zugang
                erleichterte die Versorgung. Dieses enge Zusammenleben war auf
                Burgen weniger verbreitet, da dort ausreichend Platz für
                separate Wirtschaftsgebäude zur Verfügung stand. Dennoch blieb
                der enge Bezug zu Tieren auf beiden Ebenen des mittelalterlichen
                Lebens allgegenwärtig. <br /><br />
                <b>Doch Schlaf konnte auch gefährlich sein</b>
                <br />
                Schlaf bedeutete im Mittelalter nicht nur Erholung, sondern war
                auch mit Risiken verbunden. Nächtliche Überraschungsangriffe,
                Feuergefahr durch offene Kamine, tödliche Kälte oder Krankheiten
                durch feuchte Schlafstätten waren reale Gefahren. Besonders in
                Adelshäusern konnte Misstrauen herrschen – vergiftete Speisen
                oder Attentate im Schlaf waren keine Seltenheit. Zudem litten
                viele unter Ungeziefer, das Krankheiten verbreitete. Wer auf
                einer Burg ruhte, war zwar geschützt von Mauern, doch nicht
                immer vor den Schatten der Nacht.
                <br />
                Wer weiß, ob die thüringische Besetzung der Cent Dautphe im Jahr
                1238 nicht mit einem nächtlichen Überraschungsangriff begann?
                Die Verteidigungsstruktur des Hohenfels-Komplexes war jedenfalls
                so strategisch geschickt angelegt, dass die Herren von Hohenfels
                ihn noch 30 bis 50 Jahre als Residenz behaupten konnten. <br /><br />
                <b>Was lernen wir daraus?</b>
                <br />
                Der Schlaf auf einer Burg war mehr als nur eine Nacht zwischen
                Strohlagern und Steinwänden – er war ein Spiegel der
                Gesellschaft. Von der einfachen Magd bis zum Burgherrn zeigte
                sich in der Schlafstätte, wo jemand stand. Während die Herren
                von Hohenfels über die Jahrzehnte bis 1277/1300 mehr Komfort
                genossen, blieb das einfache Volk auf harte Holzpritschen und
                wärmende Schaffelle angewiesen. Doch eines blieb für alle
                gleich: Die Gewissheit, dass mit der Morgendämmerung ein neuer
                Tag voller Herausforderungen auf sie wartete. Der Hohenfels
                selbst schläft heute noch unter einer Decke aus Bäumen, Laub und
                Humus. Er träumt von seiner Glanzzeit: derjenigen die
                Jahrhunderte zurück liegt und derjenigen, die vielleicht wieder
                kommt. Bis dahin aber - so sagt man, flüstert er denjenigen, die
                genau hinhören, seine Geschichten zu..
                <br /><br />
                <b>Du möchtest eine persönliche Führung auf dem Hohenfels?</b>
                <ul>
                  <li>Besuche uns am Tag des offenen Denkmals oder</li>
                  <li>
                    Wir bieten auch Gruppenführungen an – gegen Spendenangebot
                    und nach Terminabsprache!
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Card id="backToStart">
            <Card.Body>
              <a href="/"> Zurück zur Startseite </a>
            </Card.Body>
          </Card>
        </Container>

        {/* Modal to show the larger image */}
        <Modal
          show={this.state.showModal}
          onHide={this.handleCloseModal}
          size="xl"
          centered
          className="modal-card"
        >
          <Modal.Body>
            <img
              src={this.state.selectedImage}
              alt="Enlarged"
              className="img-fluid"
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.handleCloseModal}
              className="btn btn-secondary"
            >
              Schließen
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CompetionQuestions;
